import React, { useState } from 'react';
import { LiFiWidget } from '@lifi/widget';
import { ChainType } from '@lifi/sdk';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import InputForm from './InputForm';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const App: React.FC = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const initialToAddress = query.get('toAddress');
  const [toAddress, setToAddress] = useState<string | null>(initialToAddress);

  const handleAddressSubmit = (address: string) => {
    setToAddress(address);
    navigate(`?toAddress=${address}`);
  };

  return (
    <div style={toAddress ? {} : appStyles.background}>
      {toAddress ? (
        <>
          <Header walletAddress={toAddress} />
          <LiFiWidget
            config={{
              toChain: parseInt(process.env.REACT_APP_TO_CHAIN!, 10),
              toToken: process.env.REACT_APP_TO_TOKEN!,
              hiddenUI: ['toToken'],
              disabledUI: ['toAddress'],
              toAddress: { address: toAddress, chainType: ChainType.EVM },
              fromChain: 1,
              theme: {
                container: {
                  boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.08)',
                  borderRadius: '16px',
                },
              },
            }}
            integrator={process.env.REACT_APP_INTEGRATOR!}
          />
        </>
      ) : (
        <InputForm onSubmit={handleAddressSubmit} />
      )}
    </div>
  );
};

const appStyles = {
  background: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#33cb82',
    fontFamily: 'Mona-Sans, sans-serif',
  },
};

export default App;
