import React, { useState } from 'react';

interface InputFormProps {
  onSubmit: (address: string) => void;
}

const InputForm: React.FC<InputFormProps> = ({ onSubmit }) => {
  const [inputAddress, setInputAddress] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (isValidAddress(inputAddress)) {
      setError('');
      onSubmit(inputAddress);
    } else {
      setError('Invalid wallet address.');
    }
  };

  const isValidAddress = (address: string) => {
    // Add your address validation logic here
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  return (
    <div style={inputStyles.container}>
      <img src={require('./bolideLogo.webp')} alt="Logo" style={inputStyles.logo} />
      <h2 style={inputStyles.title}>Top up your wallet</h2>
      <p style={inputStyles.subtitle}>Enter wallet address</p>
      <form onSubmit={handleSubmit} style={inputStyles.form}>
        <input
          type="text"
          value={inputAddress}
          onChange={(e) => setInputAddress(e.target.value)}
          placeholder="Enter wallet address"
          style={inputStyles.input}
        />
        <button type="submit" style={inputStyles.button}>Submit</button>
      </form>
      {error && <p style={inputStyles.error}>{error}</p>}
    </div>
  );
};

const inputStyles = {
  container: {
    textAlign: 'center' as 'center',
    padding: '20px',
    backgroundColor: '#33cb82',
    fontFamily: 'Mona-Sans, sans-serif',
  },
  logo: {
    width: '180px',
    marginBottom: '20px',
    position: 'absolute' as 'absolute',
    top: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center' as 'center',
  },
  title: {
    textAlign: 'center' as 'center',
    fontFamily: 'Mona-Sans, sans-serif',
    fontSize: '72px',
    fontStyle: 'italic' as 'italic',
    fontWeight: 700,
    lineHeight: '90%',
    letterSpacing: '-1.44px',
    margin: '0 0 10px 0',
  },
  subtitle: {
    textAlign: 'center' as 'center',
    fontFamily: 'Mona-Sans, sans-serif',
    fontSize: '24px',
    margin: '0 0 20px 0',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    margin: '10px 0',
    width: '80%',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#152328',
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
};

export default InputForm;
