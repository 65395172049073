import React from 'react';

interface HeaderProps {
  walletAddress: string;
}

const Header: React.FC<HeaderProps> = ({ walletAddress }) => {
  return (
    <div style={headerStyles.headerContainer}>
      <h1 style={headerStyles.title}>Fund Your Wallet Easily</h1>
      <p style={headerStyles.subtitle}>
        Connect your Web3 wallet and use any token to top up your Bolide account. The tokens will be automatically converted to USDC on the Base chain.
      </p>
      <p style={headerStyles.walletAddress}>
        Bolide Wallet Address: {walletAddress}
      </p>
    </div>
  );
};

const headerStyles = {
  headerContainer: {
    textAlign: 'center' as 'center',
    padding: '20px',
    backgroundColor: '#33cb82',
    borderBottom: '1px solid #ddd',
    marginBottom: '20px',
    fontFamily: 'Mona-Sans, sans-serif',
  },
  title: {
    fontSize: '28px',
    margin: '0 0 10px 0',
  },
  subtitle: {
    fontSize: '12px',
    margin: '0 0 10px 0',
  },
  walletAddress: {
    fontSize: '12px',
    fontWeight: 'bold',
    margin: '0',
  },
};

export default Header;
